import { requestUrl } from "./backendUrl";
import { frontEndUrl } from '../config';

export const crudService = {
  get,
  post,
  put,
  patch
};

function buildUrl(endpoint) {
  return frontEndUrl + "/" + requestUrl.backendUrl + endpoint;
}

function getUser() {
  let user = JSON.parse(localStorage.getItem('user'));
  const loginAsUserToken = JSON.parse(localStorage.getItem('loginAsUser'));
  if (loginAsUserToken) {
    user = loginAsUserToken;
  }
  return user;
}

function get(endpoint, params) {
  const url = buildUrl(endpoint);
  const user = getUser();

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': user.tokenType + ' ' + user.accessToken
    }
  };

  return fetch(url, requestOptions).then(handleResponse);
}

function post(endpoint, jsonConfig, isFile) {
  const url = buildUrl(endpoint);
  const user = getUser();

  const requestOptions = {
    method: "POST",
    body: jsonConfig,
    headers: {
      'Authorization': user.tokenType + ' ' + user.accessToken
    }
  };

  if (!isFile) {
    requestOptions.headers["Content-Type"] = "application/json";
    requestOptions.body = JSON.stringify(jsonConfig);
  }

  return fetch(url, requestOptions).then(handleResponse);

}

function put(endpoint, jsonConfig, isFile) {
  const url = buildUrl(endpoint);
  const user = getUser();

  const requestOptions = {
    method: "PUT",
    body: jsonConfig,
    headers: {
      'Authorization': user.tokenType + ' ' + user.accessToken
    }
  };

  if (!isFile) {
    requestOptions.headers["Content-Type"] = "application/json";
    requestOptions.body = JSON.stringify(jsonConfig);
  }

  return fetch(url, requestOptions).then(handleResponse);

}

function patch(endpoint, jsonConfig) {
  const url = buildUrl(endpoint);
  const user = getUser();

  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify(jsonConfig),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': user.tokenType + ' ' + user.accessToken
    }
  };

  return fetch(url, requestOptions).then(handleResponse);

}

function handleResponse(response) {
  if (!response.ok) {
    if (response.status === 401) {
      localStorage.removeItem('user');
      window.location.href = "/login";
    }
    return Promise.reject(response);
  }

  const responseCloned = response.clone();
  return response.json().catch(
    () => {
      return responseCloned;
    }
  );
}
