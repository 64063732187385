import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { Column } from 'simple-flexbox';
import '../assets/css/t365/main.scss';
import '../assets/css/trustbucks/main.scss';

import { whiteLabel } from '../config';
import { menuList } from '../utils/menuList';

import { FETCH_PERMISSIONS } from '../actions/types';
const store =  require('../reducers/index');

class SideBar extends Component {
  state = {
    menu: menuList,

    access: [],
    roleId: "",
    agentType: ""
  };

  subscribeFunction = null;

  componentDidMount() {
    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;
      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access,
          roleId: state.roleId,
          agentType: state.agentType
        });
      }
    });
  };

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  }

  checkPageAccessCategory = (categoryName, linkName) => {
    const { access, agentType, roleId } = this.state;
    const freeAccessed = ["MY_ACCOUNT", "API", "AFFILIATE_LINKS"];

    // Do not show "My Commission" tab for Introducers
    if (linkName === "My Commission" && agentType === "INTRODUCER") {
      return false;
    }

    if (freeAccessed.some(elem => elem === categoryName)) {
      return true;
    } else if (categoryName === "COMPANIES" || categoryName === "PSPS") {
      return roleId === "MASTER_TECH";
    }

    const foundPermissions = access.filter(elem => elem.category === categoryName);
    return foundPermissions.some(elem => elem.state);
  };

  displayMenuItem = (menu) => {
    const { access } = this.state;
    if (menu.submenu.find(elem => elem.access === "MY_ACCOUNT" ||
          elem.access === "API" || elem.access === "AFFILIATE_LINKS")) {
      return true;
    }

    const foundItems = access.filter(permission => menu.submenu.some(elem => permission.category === elem.access));
    return foundItems.some(elem => elem.state);
  };

  sortMenu = (menu) => {
    menu.sort((elemA, elemB) => {
      if (elemA.name < elemB.name)
        return -1;
      if (elemA.name > elemB.name)
        return 1;
      return 0;
    });

    return menu;
  };

  render() {
    return (
      <div className={ `${whiteLabel} sidebar` }>
        <div className="list-menus">
          {this.state.menu.map((menu, i) =>
          { return this.displayMenuItem(menu) && (
            <Column key={ i } className="menublock">
              <Column className={ `${whiteLabel} header` }>{menu.header}</Column>
              {this.sortMenu(menu.submenu).map((item, j) => {
                return this.checkPageAccessCategory(item.access, item.name) && (
                  <NavLink
                    key={ j }
                    to={ item.url }
                    className={ `${whiteLabel} menuItem` }
                  >
                    {item.name}
                  </NavLink>
                );
              })}
            </Column>
          );
          })}
        </div>
      </div>
    );
  }
}

export default SideBar;
