import React, { Component } from 'react';
import { Row, Column } from 'simple-flexbox';
import Datetime from 'react-datetime';
import DatePicker from 'react-datepicker';
import { Panel, Button, Table } from "react-bootstrap";
import Pagination from '@mui/material/Pagination';

import ReportIcon from '../../../assets/images/report.png';
import Calendar from '../../../assets/images/calendar.png';

import Alert from "../Alert";
import ComponentHeader from "../../componentHeader";
import Dropdowns from '../apiData/dropdowns';
import Form from "../../../core/Form";
import Modal from '../Modal';
import Select from "../Select";
import Spinner from '../../Spinner';

import "react-datepicker/dist/react-datepicker.css";

import "../../../assets/css/generateReports.css";
import '../../../assets/css/apiData.css';

import { whiteLabel } from '../../../config';
import { crudActions } from "../../../services/crudActions";
import { saveAs } from 'file-saver';
import { getFormattedDate, convertUTCToLocalDate, convertLocalToUTCDate } from "../../../utils/utils.js";
import { FETCH_PERMISSIONS } from '../../../actions/types';

const INTEGER_MAX = 2147483647;

const store =  require('../../../reducers/index');

class GenerateReport extends Component {
  state = {
    controls: {
      timePeriod: {
        value:'thisMonth'
      },
      from: this.getPeriod(1).from,
      to: this.getPeriod(1).to
    },
    dateFieldDisabled: true,
    dropdowns: {
      periods: Dropdowns.periods
    },
    timeStamp: {
      fromDateTimeStamp: this.getPeriod(2).from,
      toDateTimeStamp: this.getPeriod(2).to
    },
    lastUpdate: Datetime.moment().format('HH:mm - DD. MMM YYYY'),

    reportTypes: [{
      label: "Commissions",
      value: "AGENT_COMMISSIONS"
    }, {
      label: "Settlements",
      value: "PAY_COMMISSIONS"
    }],

    sortByOptions: [{
      label: "Date",
      value: "DATE"
    }, {
      label: "Referral",
      value: "REFERRAL"
    }],

    reportFormData: {
      agentIds: [],
      reportType: "",
      displayMode: "LIST"
    },

    reportFormRules: [{
      name: "agentIds",
      type: "isArray",
      rules: {
        required: true,
        min: 1
      }
    }, {
      name: "reportType",
      type: "isString",
      rules: {
        required: true
      }
    }],

    reportForm: {},

    endpointGenerateMap: {
      AGENT_COMMISSIONS: {
        LIST: "v2/reports/agent/agent-commissions"
      },
      PAY_COMMISSIONS: {
        LIST: "v2/reports/agent/settlements"
      }
    },

    endpointXlsxMap: {
      AGENT_COMMISSIONS: {
        LIST: "v2/reports/agent/agent-commissions/xlsx"
      },
      PAY_COMMISSIONS: {
        LIST: "v2/reports/agent/settlements/xlsx"
      }
    },

    defaultTimePeriods: {
      AGENT_COMMISSIONS: {
        id: 2,
        value: 'thisMonth',
        label: 'This Month'
      },
      PAY_COMMISSIONS: {
        id: 2,
        value: 'thisMonth',
        label: 'This Month'
      }
    },

    reportsGenerated: {},
    savedGeneratedReport: {},
    savedSelectedCompany: "",
    generatedReportType: "",

    access: [],

    isLoading: false,
    isLoadingLookups: true,
    showAlert: false,
    alertType: "success",
    alertMessage: "",
    enableButton: false,
    modalTitle: "",
    modalData: "",
    modalType: "text",
    showModal: false,

    isExportSettingsOpen: false,
    isReportErrorFields: false,
    currentPage: 1,
    rowsAmount: 100,
    clickButton: false,
    cnt: 0,
    periodId: 0 // today
  };

  exportNode = null;

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    document.addEventListener("mousedown", this.handleClickOutside, false);
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside, false);
  };

  handleClickOutside = (event) => {
    if (this.sortNode && this.sortNode.contains(event.target)) {
      this.setState({
        isExportSettingsOpen: false
      });

      return;
    }

    if (this.exportNode && this.exportNode.contains(event.target)) {
      this.setState({
        isSortSettingsOpen: false
      });

      return;
    }

    this.setState({
      isExportSettingsOpen: false,
      showAlert: false,
      alertType: "success",
      alertMessage: ""
    });
  };

  thisMonth = true;

  componentDidMount() {
    const { reportFormRules, reportFormData } = this.state;
    this.setState({
      reportForm: new Form(reportFormData, reportFormRules)
    }, this.loadLookupsData);

    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        access: storeState.access
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access
        });
      }
    });
  };

  loadLookupsData() {
    const { reportForm, reportTypes } = this.state;

    crudActions.get(`v1/myaccount/me`).then(
      data => {
        if (data) {
          this.setState({
            reportForm: Object.assign(reportForm, {
              agentIds: [data.id]
            }),
            currentAgentId: data.id,
            isLoadingLookups: false
          }, () => {
            const reportType = reportTypes[0];
            this.onValueChange(reportType, "reportType");
          });
        }
      }
    );
  };

  checkAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }
    return foundPermission.state;
  };

  unionBy = (arrays, iteratee) => {
    const map = {};

    arrays.forEach((array) => {
      array.forEach((object) => {
        map[object[iteratee]] = object;
      });
    });

    return Object.values(map);
  };

  preselectReportFields = (reportType) => {
    const { defaultTimePeriods } = this.state;

    this.onSelectPeriodChange(defaultTimePeriods[reportType], "timePeriod");
  };

  defineReportForm = (reportType) => {
    const { reportFormRules, reportFormData, currentAgentId, reportForm } = this.state;

    const copyArray = (arr) => {
      return JSON.parse(JSON.stringify(arr));
    };

    const agentCommissionsForm = new Form(
      Object.assign({}, reportFormData, {
        agentIds: [currentAgentId],
        reportType: reportType,
        displayMode: "LIST",
        sortBy: "DATE"
      }),
      copyArray(reportFormRules)
    );

    const payCommissionsForm = new Form(
      Object.assign({}, reportFormData, {
        agentIds: [currentAgentId],
        reportType: reportType,
        displayMode: "LIST"
      }),
      copyArray(reportFormRules)
    );

    if (reportForm.reportType === "AGENT_COMMISSIONS") {
      return agentCommissionsForm;
    }

    return payCommissionsForm;
  };

  onValueChange = (newValue, fieldName) => {
    let { reportForm } = this.state;
    const { defaultTimePeriods, isReportErrorFields } = this.state;
    if (typeof newValue === "boolean") {
      reportForm = Object.assign(reportForm, {
        [fieldName]: !newValue
      });
    } else if (newValue.value) {
      reportForm = Object.assign(reportForm, {
        [fieldName]: newValue.value
      });
    } else if (newValue.target) {
      reportForm = Object.assign(reportForm, {
        [fieldName]: newValue.target.value
      });
    } else {
      reportForm = Object.assign(reportForm, {
        [fieldName]: newValue.map(elem => elem.value)
      });
    }

    this.setState({
      currentPage: 1
    });

    const reportType = reportForm.reportType;

    const isReportType = fieldName === "reportType";
    if (isReportType) {
      this.onSelectPeriodChange(defaultTimePeriods[reportType], "timePeriod");
    }

    if (isReportErrorFields) {
      reportForm.isFormValid();
    }

    this.setState({
      reportForm: isReportType ? this.defineReportForm(reportType) : reportForm
    }, () => {
      if (isReportType) {
        this.preselectReportFields(reportType);
      }
    });
  };

  getFields = () => {
    const { reportForm } = this.state;

    const baseFields = [
      {
        id: "empty"
      }, {
        id: "empty"
      }, {
        id: "empty"
      }, {
        id: "empty"
      }];

    const buttonField = [{
      id: "button",
      type: "button"
    }];

    const reportFields = {
      AGENT_COMMISSIONS: [
        [{}, {}, {
          id: "bulkButton",
          type: "bulkButton"
        }]
      ],
      PAY_COMMISSIONS: [
        [{}, {}, {
          id: "bulkButton",
          type: "bulkButton"
        }]
      ]
    };

    const buildFields = (type) => {
      const fields = reportFields[type];
      fields.splice(0, 0, baseFields);
      fields[fields.length - 1] = fields[fields.length - 1].concat(buttonField);

      return fields;
    };

    const selectedReportFields = reportFields[reportForm.reportType];

    if (!selectedReportFields) {
      return buildFields('AGENT_COMMISSIONS');
    }

    return buildFields(reportForm.reportType);
  };

  isDateValid = (current, state) => {
    const { timeStamp, fromDate = timeStamp.fromDateTimeStamp, toDate = timeStamp.toDateTimeStamp, reportForm, timeZones } = this.state;
    let timeZoneOffset = "00:00";
    if (reportForm.timeZone && timeZones.length) {
      timeZoneOffset = timeZones.find(zone => zone.value === reportForm.timeZone).offset;
    }
    const yesterday = Datetime.moment().utc().utcOffset(timeZoneOffset).subtract(1, 'days');

    if (state === 'fromDateTimeStamp') {
      return Datetime.moment(current).isBefore(!fromDate ? yesterday : toDate);
    }

    return Datetime.moment(current).isAfter(!toDate ? yesterday : Datetime.moment(fromDate).subtract(1, 'days'));
  };

  getPeriod(periodType) {
    const timeZoneOffset = "00:00";
    const moment = Datetime.moment;

    switch (periodType) {
    case 1: // yesterday
      return {
        from: moment().utc().utcOffset(timeZoneOffset).subtract(1, 'day').hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset).subtract(1, 'day').hour(23).minute(59).second(59)
      };
    case 2: // this month
      return {
        from: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year()).month(moment().utc().utcOffset(timeZoneOffset).month()).date(1).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset)
      };
    case 3: // last month
      return {
        from: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year()).month(moment().utc().utcOffset(timeZoneOffset).month() - 1).date(1).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year()).month(moment().utc().utcOffset(timeZoneOffset).month() - 1)
          .date(moment().utc().utcOffset(timeZoneOffset).month(moment().utc().utcOffset(timeZoneOffset).month() - 1).daysInMonth()).hour(23).minute(59).second(59)
      };
    case 4: // this year
      return {
        from: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year()).month(0).date(1).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset)
      };
    case 5: // last year
      return {
        from: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year() - 1).month(0).date(1).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year() - 1).month(11).date(31).hour(23).minute(59).second(59)
      };
    case 6: // last hour
      return {
        from: moment().utc().utcOffset(timeZoneOffset).subtract(1, 'hours'),
        to: moment().utc().utcOffset(timeZoneOffset)
      };
    case 7: // last 6 hours
      return {
        from: moment().utc().utcOffset(timeZoneOffset).subtract(6, 'hours'),
        to: moment().utc().utcOffset(timeZoneOffset)
      };
    case 0: // today
    default: // custom period
      return {
        from: moment().utc().utcOffset(timeZoneOffset).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset)
      };
    }
  };

  onSelectPeriodChange = (value, id) => {
    const timeStamp = {...this.state.timeStamp};
    const controls = {...this.state.controls};
    value = value === null ? {
      id: 0,
      value: 'today',
      label: 'Today'
    } : value;
    const dateFieldDisabled = value.id > 0 && value.id <= 7; // depends on any predefined period (today, yesterday etc.)
    const period = this.getPeriod(value.id);

    controls[id] = value;
    timeStamp.fromDateTimeStamp = period.from;
    timeStamp.toDateTimeStamp = period.to;
    controls.from = getFormattedDate(timeStamp.fromDateTimeStamp._d);
    controls.to = getFormattedDate(timeStamp.toDateTimeStamp._d);

    //if this month
    if (value.id === 2) {
      this.thisMonth = true;
    } else {
      this.thisMonth = false;
    }

    this.setState({
      controls: controls,
      dateFieldDisabled: dateFieldDisabled,
      timeStamp: timeStamp,
      currentPage: 1,
      periodId: value.id
    });
  };

  handleDateChange = (event, state, fieldState) => {
    const controls = {...this.state.controls};
    const timeStamp = {...this.state.timeStamp};

    timeStamp[state] = event;
    controls[fieldState] = event;

    this.setState({controls: controls, timeStamp: timeStamp, isSubmitting: false});
  };

  filterHeaders = (headers) => {
    const { access } = this.state;

    return headers.filter(elem => {
      if (!elem.permission) {
        return true;
      }
     
      const foundPermission = access.find(accessElem => accessElem.permission === elem.permission);
      if (!foundPermission) {
        return true;
      }

      return foundPermission.state;
    });
  };

  filterData = (data) => {
    const { reportsGenerated, access } = this.state;
    return data.filter((elem, index) => {

      const header = reportsGenerated.headers[index];

      if (!header.permission) {
        return true;
      }

      const foundPermission = access.find(accessElem => accessElem.permission === header.permission);
      if (!foundPermission ) {
        return true;
      }
      
      return foundPermission.state;
    });
  };

  onGenerateReport = () => {
    const { reportForm, timeStamp, endpointGenerateMap, currentPage, rowsAmount, periodId } = this.state;
    const isReportFormValid = reportForm.isFormValid();
    this.setState({
      reportForm: reportForm,
      isReportErrorFields: true,
      clickButton: true
    });

    if(reportForm.transactionId){
      this.setState({
        enableButton: true
      });
    }

    if (isReportFormValid) {
      this.setState({
        isLoading: true
      });

      const reportFormData = reportForm.data();
      
      const dateFormat = "DD.MM.YYYY HH:mm:ss";
      
      const period = this.getPeriod(periodId);

      if (periodId !== 8) {
        //we do not override dates for custom date picker
        timeStamp.fromDateTimeStamp = period.from;
        timeStamp.toDateTimeStamp = period.to;
      }

      const timeZoneOffset = "00:00";

      let fromDateTimeStamp = Datetime.moment(timeStamp.fromDateTimeStamp).utc().utcOffset(timeZoneOffset).format(dateFormat);
      let toDateTimeStamp = Datetime.moment(timeStamp.toDateTimeStamp).utc().utcOffset(timeZoneOffset).format(dateFormat);

      if (periodId === 8) {
        fromDateTimeStamp = Datetime.moment(timeStamp.fromDateTimeStamp).format(dateFormat);
        toDateTimeStamp = Datetime.moment(timeStamp.toDateTimeStamp).format(dateFormat);
      }

      const endpoint = endpointGenerateMap[reportForm.reportType]["LIST"];

      crudActions.post(
        endpoint,
        Object.assign(reportFormData, {
          fromDate: fromDateTimeStamp,
          toDate: toDateTimeStamp,
          pageNumber: currentPage,
          limit: rowsAmount
        })).then(
        (reportsGenerated) => {
          if (reportsGenerated) {
            if (reportsGenerated.status && reportsGenerated.id) {
              this.setState({
                showAlert: true,
                alertType: "error",
                alertMessage: "Oops, something went wrong. Please, try again or change filter parameters.",
                isLoading: false
              });
            } else {
              reportsGenerated.isHistory = true;

              if(reportsGenerated.warningMessage) {
                this.setState({
                  showAlert: true,
                  alertType: "warning",
                  alertMessage: reportsGenerated.warningMessage,
                });
              }
              this.setState({
                reportsGenerated: reportsGenerated,
                generatedReportType: reportForm.reportType,
                savedGeneratedReport: reportFormData,
                savedSelectedCompany: reportFormData.companyId,
                isLoading: false,
                cnt: reportsGenerated.cnt,
                lastUpdate: Datetime.moment().format('HH:mm - DD. MMM YYYY')
              });
            }
          }
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              isLoading: false
            });
          }
        }
      );
    } else {
      this.setState({
        showAlert: true,
        alertType: "error",
        alertMessage: "Some fields have errors. Please, fix them in order to continue."
      });
    }
  };

  onExportClick = () => {
    const { reportForm, timeStamp, endpointXlsxMap, periodId } = this.state;
    const reportFormData = reportForm.data();

    this.setState({
      isExportSettingsOpen: false
    });

    const reportType = reportFormData.reportType;
    const displayMode = reportFormData.displayMode;

    const dateFormat = "DD.MM.YYYY HH:mm:ss";

    const period = this.getPeriod(periodId);

    if (periodId !== 8) {
      //we do not override dates for custom date picker
      timeStamp.fromDateTimeStamp = period.from;
      timeStamp.toDateTimeStamp = period.to;
    }

    const timeZoneOffset = "00:00";

    let fromDateTimeStamp = Datetime.moment(timeStamp.fromDateTimeStamp).utc().utcOffset(timeZoneOffset).format(dateFormat);
    let toDateTimeStamp = Datetime.moment(timeStamp.toDateTimeStamp).utc().utcOffset(timeZoneOffset).format(dateFormat);

    if (periodId === 8) {
      fromDateTimeStamp = Datetime.moment(timeStamp.fromDateTimeStamp).format(dateFormat);
      toDateTimeStamp = Datetime.moment(timeStamp.toDateTimeStamp).format(dateFormat);
    }

    const endpoint = endpointXlsxMap[reportType][displayMode];

    crudActions.post(
      endpoint,
      Object.assign(reportFormData, {
        fromDate: fromDateTimeStamp,
        toDate: toDateTimeStamp,
        pageNumber: 1,
        limit: INTEGER_MAX,
      })).then(response => {
      if (response) {
        response.blob().then(
          result => {
            const dateTime = Datetime.moment().format("DD-MM-YYYY HH-mm-ss");
            saveAs(result, `${dateTime} - ${reportType}.xlsx`);
          }
        );
      }
    }).catch(
      err => {
        this.setState({
          showAlert: true,
          alertType: "error",
          alertMessage: err.message
        });
      }
    );
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: ""
    });
  };

  onExportOpen = () => {
    this.setState({
      isExportSettingsOpen: !this.state.isExportSettingsOpen
    });
  };

  onOpenModalClick = (modalData, type) => {
    try {
      this.setState({
        showModal: true,
        modalType: type,
        modalTitle: "Notes",
        modalData
      });
    } catch {
      this.setState({
        showAlert: true,
        alertType: "error",
        alertMessage: "Getting notes failed.",
      });
    }
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      modalData: "",
      modalType: "text",
      modalTitle: ""
    });
  };

  isRightAligned = (index) => {
    const { reportsGenerated } = this.state;
    return reportsGenerated.headers[index].headerType === "NUMBER" || reportsGenerated.headers[index].headerType === "STRING";
  };

  handleChangePage = (event) => {
    const { currentPage } = this.state;
    let pageNum = currentPage;

    if (event.target.getAttribute('data-testid') === "NavigateBeforeIcon" 
    || event.target.getAttribute('aria-label') === "Go to previous page") {
      pageNum--;
    } else if (event.target.getAttribute('data-testid') === "NavigateNextIcon"
    || event.target.getAttribute('aria-label') === "Go to next page") {
      pageNum++;
    } else
      if (event.target.textContent) {
        pageNum = event.target.textContent;
      }
   
    this.setState({ currentPage: pageNum }, () => {
      this.onGenerateReport();
    }); 
  };

  getIcon = () => {
    return ReportIcon;
  };

  render() {
    const controls = { ...this.state.controls };
    const {
      clickButton,
      cnt,
      currentPage,
      generatedReportType,
      isLoading,
      isLoadingLookups,
      isExportSettingsOpen,
      reportForm,
      reportsGenerated,
      reportTypes,
      rowsAmount,
      timeStamp,
      showAlert,
      alertType,
      alertMessage,
      modalTitle,
      modalData,
      modalType,
      showModal,
      sortByOptions
    } = this.state;
    
    const rows = this.getFields();

    const totalPageCount = Math.ceil(cnt / rowsAmount);

    if (!isExportSettingsOpen) {
      this.exportNode = null;
    }

    if (!reportForm.originalData) {
      return <Row flexGrow={ 1 } className='module generateReports' vertical='start'>
        <span/>
      </Row>;
    }

    return (
      <Row flexGrow={ 1 } className={ `${whiteLabel} module generateReports` } vertical='start'>
        <Column flexGrow={ 1 }>
          <ComponentHeader
            title="Generate Report"
            lastUpdate={ this.state.lastUpdate }
            img={ this.getIcon() } />
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading className={ whiteLabel }>
                  <Panel.Title>
                    GENERATE REPORT
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content" style={ {overflow: 'unset'} }>
                    {isLoadingLookups ? (
                      <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                        <Spinner smallContainer={ true } />
                      </div>
                    ) : (
                      <>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                          <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                              <label> Report Type </label>
                              <Select
                                id="reportType"
                                name="reportType"
                                value={  reportForm.reportType }
                                required={ true }
                                clearable={ false }
                                disabled={ isLoading }
                                onChange={ (value) => this.onValueChange(value, 'reportType') }
                                options={ reportTypes }
                              />
                            </Column>
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                              {reportForm.reportType === "AGENT_COMMISSIONS" ? (
                                <>
                                  <label> Sort By </label>
                                  <Select
                                    id="sortBy"
                                    name="sortBy"
                                    value={  reportForm.sortBy }
                                    required={ true }
                                    clearable={ false }
                                    disabled={ isLoading }
                                    onChange={ (value) => this.onValueChange(value, 'sortBy') }
                                    options={ sortByOptions }
                                  />
                                </>
                              ) : (
                                <span/>
                              )}

                            </Column>
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                              <span/>
                            </Column>
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                              <span/>
                            </Column>
                          </Row>
                        </Row>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Time Period </label>
                            <Select
                              id="timePeriod"
                              name="timePeriod"
                              value={ controls.timePeriod.value || '' }
                              required={ true }
                              clearable={ false }
                              disableSort={ true }
                              disabled={ isLoading }
                              onChange={ (value) => this.onSelectPeriodChange(value, 'timePeriod') }
                              options={ this.state.dropdowns.periods }
                            />
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label>From Date</label>
                            <img src={ Calendar } className="calendar-svg" alt=""/>
                            <DatePicker
                              selected={ convertUTCToLocalDate(timeStamp.fromDateTimeStamp) || '' }
                              className="form-control"
                              timeInputLabel="Time:"
                              dateFormat={ `dd.MM.yyyy HH:mm` }
                              timeFormat="HH:mm"
                              showTimeSelect={ true }
                              timeIntervals={ 15 }
                              filterDate={ (event) => this.isDateValid(event, 'fromDateTimeStamp') }
                              disabled={ isLoading }
                              onChange={ (date) => this.handleDateChange(convertLocalToUTCDate(date), 'fromDateTimeStamp', 'from') }
                            />
                          </Column>
                          
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label>To Date</label>
                            <img src={ Calendar } className="calendar-svg" alt=""/>
                            <DatePicker
                              selected={ convertUTCToLocalDate(timeStamp.toDateTimeStamp) || '' }
                              className="form-control"
                              timeInputLabel="Time:"
                              dateFormat={ `dd.MM.yyyy HH:mm` }
                              timeFormat="HH:mm"
                              showTimeSelect={ true }
                              timeIntervals={ 15 }
                              filterDate={ (event) => this.isDateValid(event, 'toDateTimeStamp') }
                              disabled={ isLoading }
                              onChange={ (date) => this.handleDateChange(convertLocalToUTCDate(date), 'toDateTimeStamp', 'to') }
                            />
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                            <span/>
                          </Column>
                        </Row>
                        {rows.map((row, index) => {
                          return (
                            <Row key={ index } flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                              {[2, 4].map(count => {
                                return (
                                  <Row key={ index + " " + count } flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                                    {row.slice(count - 2, count).map((field, fieldIndex) => {
                                      return field.id !== "empty" && (
                                        <Column
                                          key={ field.id + " " + index + " " + fieldIndex }
                                          flexGrow={ field.flexGrow !== undefined ? parseFloat(field.flexGrow) : 1 }
                                          vertical='end'
                                          alignSelf='start'
                                          className="input-column"
                                        >
                                          {field.type === "button" && !field.notShowOnCondition ? (
                                            <Button
                                              type="submit"
                                              className="btn defaultBtn"
                                              disabled={ isLoading }
                                              style={ { outline: isLoading ? "5px auto -webkit-focus-ring-color" : "" } }
                                              onClick={ this.onGenerateReport }
                                            >
                                              GENERATE
                                            </Button>
                                          ) : (
                                            <span/>
                                          )}
                                        </Column>
                                      );
                                    })}
                                  </Row>);
                              })}
                            </Row>);
                        })}
                      </>
                    )}
                      
                  </div>
                </Panel.Body>
              </Panel>
              { isLoading ? (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title> REPORT GENERATED </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  </Panel.Body>
                </Panel>
              ) : reportsGenerated.headers ? (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>
                      REPORT GENERATED
                    </Panel.Title>
                    <div className="settings-panel">
                      <span className="setting" style={ { float: "right", paddingRight: "10px" } } onClick={ () => this.onExportOpen() }>
                        <svg className="setting-icon" viewBox="0 0 32 32">
                          <g>
                            <path clipRule="evenodd" d="M8.624,21.336h2.015c1.402-7.953,8.329-14,16.684-14   c0.35,0,0.683,0.003,1.019,0.006l-3.664,3.663c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293   c0.256,0,0.512-0.098,0.707-0.293L32,6.356l-5.907-6.063c-0.391-0.391-1.023-0.391-1.414,0c-0.391,0.391-0.391,1.023,0,1.414   l3.631,3.631c-0.318-0.001-0.62-0.003-0.945-0.003C17.895,5.336,10.066,12.271,8.624,21.336z" fillRule="evenodd"/>
                            <path clipRule="evenodd" d="M29,15c-0.552,0-1,0.448-1,1v14H2V10h9c0.552,0,1-0.448,1-1   c0-0.552-0.448-1-1-1h-0.03H2c-1.104,0-2,0.896-2,2v20c0,1.104,0.896,2,2,2h26c1.104,0,2-0.896,2-2V16C30,15.448,29.552,15,29,15z" fillRule="evenodd"/>
                          </g>
                        </svg>
                        <span className="text"> Export </span>
                      </span>
                    </div>
                    { isExportSettingsOpen && (
                      <div ref={ node => this.exportNode = node } className="export-settings">
                        <p className="export-setting-item" onClick={ () => this.onExportClick() }> Export to Excel </p>
                      </div>
                    )}
                  </Panel.Heading>
                  <Panel.Body>
                    <div className={ `panel-content report-content ${generatedReportType.toLowerCase()}` }>
                      <Table className="table table-striped">
                        <thead>
                          <tr style={ { backgroundColor: '#223679', color: "#FFFFFF" } }>
                            {this.filterHeaders(reportsGenerated.headers).map((column, i) => {
                              return (
                                <th
                                  key={ i }
                                  className={ this.isRightAligned(i) ? "text-right" : column.headerType === "TEXT" ? "text-center" : "" }
                                >
                                  { column.headerName }
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {reportsGenerated.data.map((element, i) => {
                            return [ reportsGenerated.isHistory && (
                              <tr key={ i }>
                                {this.filterData(element).map((tableData, j) => {
                                  return reportsGenerated.headers[j].headerType === "TEXT" ? (
                                    <td
                                      key={ i.toString() + j.toString() }
                                      className={ `text-center ${!tableData || tableData === "{}" || tableData === "[]" ? "no-history" : "history"}` }
                                      onClick={ () => this.onOpenModalClick(tableData, "text") }
                                    >
                                      View
                                    </td>
                                  ) : (
                                    <td
                                      key={ i.toString() + j.toString() }
                                      className={ this.isRightAligned(j) ? "text-right " : "" }
                                      style={ { minWidth: "100px" } }
                                    >
                                      { tableData }
                                    </td>
                                  );
                                })
                                }
                              </tr>
                            )];
                          })}
                          {reportsGenerated.total && (
                            <tr className="main-row">
                              {reportsGenerated.total.map((elem, i) => {
                                return <td key={ i }>
                                  { elem }
                                </td>;
                              })}
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Panel.Body>
                </Panel>
              ) : (
                <span/>
              )}
              {clickButton && totalPageCount > 0 && (
                <Panel style={ {marginTop : "-23px", borderColor : "white", maxHeight: "105px", minHeight: "100px"} }>
                  <Panel.Body>
                    <div className="pagination">
                      <Pagination 
                        count={ totalPageCount } 
                        variant="outlined" 
                        shape="rounded" 
                        page={ Number(currentPage) }
                        onClick= { this.handleChangePage } 
                      />
                    </div>
                  </Panel.Body>
                  
                </Panel>
              )}
            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor={ "#DD6B55" }
            onConfirm={ this.onConfirm }
          />
        )}

        <Modal
          title={ modalTitle }
          handleClose={ this.handleCloseModal }
          showModal={ showModal }
          modalType={ modalType }
          data={ modalData }
          isLoading={ false }
        />

      </Row>
    );
  }
}

export default GenerateReport;