import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import { Column } from 'simple-flexbox';

class ModalView extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  };

  render() {
    const { showModal, handleClose, title, data, buttonLabel } = this.props;

    return (
      <Modal
        show={ showModal }
        onHide={ handleClose }
        backdrop="static"
        keyboard={ false }
      >
        <Modal.Header closeButton>
          <Modal.Title> { title } </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Column flexGrow={ 1 } style={ { padding: "25px", overflow: "auto" } }>
            <p style={ { whiteSpace: "pre-wrap" } }>
              { data }
            </p>
          </Column>
        </Modal.Body>
        <Modal.Footer>
          <Button className="json-modal-close" onClick={ handleClose }>
            { buttonLabel || "Close" }
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalView;
