module.exports = {
  en: {
    roles: {
      TECH_MASTER: "Tech Master",
      SUPER_ADMIN: "Super Admin",
      BASIC_USER: "Basic User",
      MERCHANT_MASTER: "Merchant Master",
      MERCHANT_BASIC: "Merchant Basic",
      MASTER_TECH: "Master Tech",
      MASTER_ADMIN: "Master Admin",
      MASTER_USER: "Master User",
      WL_ADMIN: "WL Admin",
      WL_USER: "WL User",
      MERCHANT_ADMIN: "Merchant Admin",
      MERCHANT_USER: "Merchant User",
      ADMIN: "Admin",
      USER: "User",
      AGENT: "Agent",
      INTRODUCER: "Introducer"
    },

    permissions: {
      REPORTS_DEPOSITS: "Deposits",
      REPORTS_GROSS_PROFIT: "Gross Profits",
      REPORTS_ACTIVITY: "Activity",
      REPORTS_TRANSACTIONS: "Server Logs",
      REPORTS_PAYOUT: "Payout",
      MERCHANTS_VIEW: "View",
      MERCHANTS_EDIT: "Edit",
      ADMIN_PANEL_VIEW: "View",
      ADMIN_PANEL_EDIT: "Edit",
      DASHBOARD_VIEW: "View",
      MIDS_VIEW: "View",
      MIDS_EDIT: "Edit",
      ADMIN_PANEL: "ADMIN PANEL",
      MERCHANTS: "MERCHANTS",
      REPORTS: "REPORTS",
      DASHBOARD: "DASHBOARD",
      MIDS: "MIDS",
      COMPANIES: "COMPANIES",
      COMPANY_EDIT: "Edit",
      COMPANY_VIEW: "View",
      USER_MANAGEMENT: "USER MANAGEMENT",
      USER_VIEW: "View",
      USER_EDIT: "Edit",
      PSP_CASCADING: "PSP CASCADING",
      PSP_CASCADING_VIEW: "View",
      PSP_CASCADING_EDIT: "Edit",
      PAYOUT_CREATE: "Create",
      NOTES_CREATE: "Create",
      NOTES_VIEW: "View",
      TEST_PAYMENT_CREATE: "Create test payment"
    }
  }
};